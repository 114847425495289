import { FC, useState } from "react"
import { StyleSheet, Text, TextInput, TouchableOpacityProps, View } from "react-native"

interface TimeInputProps extends TouchableOpacityProps {
    hour?: number
    minutes?: number
    onTimeChange?: (hour?: number, minutes?: number) => void
}

const TimeInput: FC<TimeInputProps> = ({ hour, minutes, style, onTimeChange, ...rest }) => {

    const [stateHour, setStateHour] = useState(hour?.toString()?.padStart(2, "0") || "");
    const [stateMinutes, setStateMinutes] = useState(minutes?.toString()?.padStart(2, "0") || "");
    // const stateHour = hour // || new Date().getHours()
    // const stateMinutes = minutes // || new Date().getMinutes()

    const onHourChange = (e: string) => {
        setStateHour(e)
        if(onTimeChange) {
            onTimeChange(Number.parseInt(e), Number.parseInt(stateMinutes))
        }
    }

    const onMinuteChange = (e: string) => {
        setStateMinutes(e)
        if(onTimeChange) {
            onTimeChange(Number.parseInt(stateHour), Number.parseInt(e))
        }
    }

    const onBlur = () => {
        setStateHour(stateHour.length > 0 ? stateHour.padStart(2, "0") : "")
        setStateMinutes(stateMinutes.length > 0 ? stateMinutes.padStart(2, "0") : "")
    }

    return (
        <View style={[styles.container, style]} {...rest}>
            <TextInput maxLength={2} value={stateHour.toString()} keyboardType="number-pad" style={styles.input} onChangeText={onHourChange} onBlur={onBlur} />
            <Text style={styles.label}>:</Text>
            <TextInput maxLength={2} value={stateMinutes.toString()} keyboardType="number-pad" style={styles.input} onChangeText={onMinuteChange} onBlur={onBlur} />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: 158,
        height: 50,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row"

    },

    label: {
        fontFamily: "SegoeBold",
        fontSize: 25,
        textAlign: "center",
        color: "#FFFFFF",
        marginStart: 6,
        marginEnd: 6,
        marginBottom: 5
    },

    input: {
        backgroundColor: "#081616",
        paddingBottom: 6,
        borderColor: "#48A9C5",
        borderRadius: 15,
        borderWidth: 3,
        width: 66,
        height: 50,
        fontFamily: "SegoeBold",
        fontSize: 25,
        textAlign: "center",
        color: "#FFFFFF",
    },
})

export default TimeInput