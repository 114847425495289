import EventInfo from "./api/response/EventInfo"

type eventType =  "time/s" | "count/generic" | "note/txt" | "credentials/pryv-api-endpoint" | "boolean/bool"

const createStream = (id: string, name: string, parentId?: string) => {
    return {
        "method": "streams.create",
        "params": {
            "id": id,
            "name": name,
            "parentId": parentId
        }
    }
}

const createEventBatch = (streams: [string], type: eventType, value?: string | number | boolean) => {
    const event: EventInfo = {
        "streamIds": streams,
        "type": type,
        "content": value,
    } 
    return {
        "method": "events.create",
        "params": event
    }
}

export { createStream, createEventBatch }
