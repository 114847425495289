import { FC } from 'react';
import { Text } from 'react-native';
import { styles } from './styles';
import { STextProps } from './types';

const SText: FC<STextProps> = (props) => {
  const { center, fs, children, style, textType } = props;

  let textStyle = {};

  switch (textType) {
    case 'bold':
      textStyle = styles.bold;
      break;
    case 'light':
      textStyle = styles.light;
      break;
    case 'semibold':
      textStyle = styles.semiBold;
      break;
    case 'sfprosemibold':
      textStyle = styles.sfProSemibold;
      break;
    default:
      textStyle = styles.regular;
      break;
  }

  const passedStyles = Array.isArray(style) ? Object.assign({}, ...style) : style;

  return (
    <Text
      {...props}
      style={[
        styles.text,
        center ? styles.center : null,
        { fontSize: fs },
        textStyle,
        passedStyles,
      ]}
    >
      {children}
    </Text>
  );
};

export default SText;
