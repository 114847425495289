import { FC } from 'react';
import {
  Image,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewStyle,
} from 'react-native';

interface CheckBoxLabelProps extends TouchableOpacityProps {
  isChecked?: boolean;
  label: string;
  style?: StyleProp<ViewStyle>;
  styleLabel?: StyleProp<TextStyle>;
  styleIconContainer?: StyleProp<ViewStyle>;
  styleIconContainerChecked?: StyleProp<ViewStyle>;
  withoutIcon?: boolean;
  onCheckedChange?: (checked: boolean) => void;
}

const CheckBoxLabel: FC<CheckBoxLabelProps> = ({
  isChecked,
  label,
  style,
  styleLabel,
  styleIconContainer,
  styleIconContainerChecked,
  onCheckedChange,
  withoutIcon,
  ...rest
}) => {
  const checked = isChecked || false;

  const onPress = () => {
    if (onCheckedChange) {
      onCheckedChange(!checked);
    }
  };

  const iconStyle: any[] = [styles.icon];
  const iconContainerStyle: any[] = [styles.iconContainer, styleIconContainer];
  if (checked) {
    iconStyle.push(styles.iconChecked);
    iconContainerStyle.push([styles.iconContainerChecked, styleIconContainerChecked]);
  }

  return (
    <View style={[styles.button, style]} {...rest}>
      <TouchableOpacity onPress={onPress} style={iconContainerStyle}>
        {!withoutIcon && (
          <Image source={require('../../assets/icon_checkmark.png')} style={iconStyle} />
        )}
      </TouchableOpacity>
      <Text style={[styles.label, styleLabel]} onPress={onPress}>
        {label}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  iconContainer: {
    borderWidth: 2,
    borderColor: '#FFFFFFB2',
    borderRadius: 15,
    width: 30,
    height: 30,
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },

  iconContainerChecked: {
    backgroundColor: '#48A9C5',
    borderColor: '#48A9C5',
  },

  iconChecked: {
    opacity: 1,
  },

  icon: {
    opacity: 0,
    width: 15,
    height: 10,
  },

  label: {
    fontFamily: 'SegoeRegular',
    fontSize: 19,
    textAlign: 'center',
    color: '#FFFFFF',
  },
});

export default CheckBoxLabel;
export { CheckBoxLabelProps };
