import { FC } from "react"
import { Button, ButtonProps, StyleProp, StyleSheet, Text, TouchableOpacity, TouchableOpacityProps, ViewStyle } from "react-native"

interface SButtonProps extends TouchableOpacityProps {
    label: string
    style?: StyleProp<ViewStyle>
}

const SButton2: FC<SButtonProps> = ({label, style, ...rest}) => {

    return (
    <TouchableOpacity style={[styles.button, style]} {...rest}>
        <Text style={styles.label}>{label}</Text>
    </TouchableOpacity>

    ) 
}

const styles = StyleSheet.create({
    button: {
        borderColor: "#48A9C5",
        borderWidth: 2,
        borderRadius: 52,
        justifyContent: "center",
        paddingStart: 14,
        paddingEnd: 14,
        paddingTop: 8,
        paddingBottom: 7,
    },

    label: {
        fontFamily: "SegoeRegular",
        lineHeight: 27,
        fontSize: 20,
        textAlign: "center",
        color: "#FFFFFF",
    }
})

export default SButton2