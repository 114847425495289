import { useState } from "react"
import { GestureResponderEvent, StyleProp, StyleSheet, Text, TouchableOpacity, TouchableOpacityProps, View, ViewStyle } from "react-native"

interface ProfileStatsItemProps extends TouchableOpacityProps {
    items: { label: string, value: any }[]
    active?: any
    style?: StyleProp<ViewStyle>
    itemStyle?: StyleProp<ViewStyle>
    itemActiveStyle?: StyleProp<ViewStyle>
    onSelected?: ((itemSelected: any, event: GestureResponderEvent) => void)
}

export default ({ items, active, style, itemStyle, itemActiveStyle, onSelected, ...rest }: ProfileStatsItemProps) => {

    if (!active && items.length > 0) {
        active = items[0].value
    }

    const [activeItem, setActiveItem] = useState(active)

    const onItemSelected = (active: any, event: GestureResponderEvent) => {
        setActiveItem(active)
        if (onSelected) {
            onSelected(active, event)
        }
    }

    const tabWidth = (100 / items.length - 1) + "%"
    return (
        <View style={styles.tab_bar} {...rest}>

            {items.map((value, i) => {
                const style: StyleProp<ViewStyle> = [styles.tab_item, itemStyle]
                if (value.value === activeItem) style.push(styles.tab_item_active, itemActiveStyle)
                return (<TouchableOpacity style={{ width: tabWidth, alignContent: "center" }} onPress={(event) => onItemSelected(value.value, event)} key={value.label}><Text style={style} >{value.label}</Text></TouchableOpacity>)
            })}
        </View>
    )
}

const styles = StyleSheet.create({
    tab_bar: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: 'space-between',
        paddingStart: 10,
        paddingEnd: 10,
        paddingBottom: 16
    },

    tab_item: {
        color: "#FFFFFF80",
        fontFamily: "SegoeRegular",
        fontSize: 15,
        paddingStart: 14,
        paddingTop: 5,
        paddingEnd: 14,
        paddingBottom: 6,
        borderRadius: 18,
        borderColor: "#FFFFFFB2",
        borderWidth: 1,
        textAlign: "center"
    },

    tab_item_active: {
        color: "#FFFFFF",
        backgroundColor: "#48A9C5",
        borderColor: "#48A9C5",
    },
})
