import { StyleSheet, TextStyle } from 'react-native';

interface STextStyles {
  text: TextStyle;
  center: TextStyle;
  regular: TextStyle;
  light: TextStyle;
  semiBold: TextStyle;
  sfProSemibold: TextStyle;
  bold: TextStyle;
}

export const styles = StyleSheet.create<STextStyles>({
  text: {
    fontSize: 18,
  },

  center: {
    textAlign: 'center',
  },

  regular: {
    fontFamily: 'SegoeRegular',
  },

  light: {
    fontFamily: 'SegoeLight',
  },

  bold: {
    fontFamily: 'SegoeBold',
  },

  semiBold: {
    fontFamily: 'SegoeSemibold',
  },

  sfProSemibold: {
    fontFamily: 'SFProSemibold',
  },
});
