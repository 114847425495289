import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

interface SectionViewProps {
    children: React.ReactChild | React.ReactChild[],
    style?: StyleProp<ViewStyle>
}

const SectionView: React.FC<SectionViewProps> = ({ children, style }: SectionViewProps) => {
    return (
        <View style={[styles.container, style]}>
          {children}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingVertical: 8,
        backgroundColor: '#000',
        opacity: 0.8,
        borderRadius: 10,
        marginTop: 16,
        marginHorizontal: 8,
        paddingHorizontal: 8
    },
})

export default SectionView;
