import { FC } from "react"
import { Image, ImageSourcePropType, StyleProp, StyleSheet, Text, TextInput, TextInputProps, TouchableOpacity, View, ViewStyle } from "react-native"

interface LoginInputProps extends TextInputProps {
    label: string
    icon?: ImageSourcePropType
    iconWidth?: number
    iconHeight?: number
    style?: StyleProp<ViewStyle>
    labelStyle?: StyleProp<ViewStyle>
    onIconPress?: () => void
}


const LoginInput: FC<LoginInputProps> = ({ onIconPress, label, icon, iconWidth, iconHeight, style, labelStyle, ...rest }) => {

    return (
        <View style={[styles.container, style]} >
            <View style={styles.labelContainer}>
                <Text style={[styles.label, labelStyle]}>{label}</Text>
                {icon && <TouchableOpacity onPress={onIconPress}><Image source={icon} style={{ width: iconWidth, height: iconHeight }} /></TouchableOpacity>}
            </View>
            <TextInput
                style={styles.input}
                {...rest}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "100%"
    },

    labelContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 20
    },

    label: {
        fontFamily: "SegoeRegular",
        fontSize: 20,
        color: "#FFFFFF",
    },

    input: {
        fontFamily: "SegoeRegular",
        fontSize: 18,
        color: "#FFFFFF",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: "#48A9C5"
    }
});

export default LoginInput