import { FC } from "react"
import { StyleProp, StyleSheet, Text, TextProps, ViewStyle } from "react-native"

interface LinkTextProps extends TextProps {
    label: string,
    style?: StyleProp<ViewStyle>,
}

const LinkText: FC<LinkTextProps> = ({ label, style, ...rest }) => {

    return (
        <Text style={[styles.label, style]} {...rest}>{label}</Text>
    )
}

const styles = StyleSheet.create({

    label: {
        color: "#FFFFFF",
        fontSize: 17,
        fontFamily: "SegoeLight",
        textAlign: "center",
        textDecorationLine: "underline"
    }
})

export default LinkText