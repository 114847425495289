import ApiInterface from "./api/ApiInterface";
import { apiCall, apiGet, apiPost } from "./api/ApiUtils";
import AccessCheckRequest from "./api/request/AccessCheckRequest";
import AccessCreateRequest from "./api/request/AccessCreateRequest";
import AuthRequest from "./api/request/AuthRequest";
import EventsListRequest from "./api/request/EventsListRequest";
import NewUserRequest from "./api/request/NewUserRequest";
import StreamsListRequest from "./api/request/StreamsListRequest";
import AccessCheckResponse from "./api/response/AccessCheckResponse";
import AccessCreateResponse from "./api/response/AccessCreateResponse";
import AccessInfo from "./api/response/AccessInfo";
import AccessListResponse from "./api/response/AccessListResponse";
import AuthResponse from "./api/response/AuthResponse";
import EventsListResponse from "./api/response/EventsListResponse";
import HostingsResponse from "./api/response/HostingsReponse";
import NewUserResponse from "./api/response/NewUserResponse";
import ServiceInfo from "./api/response/ServiceInfo";
import StreamsListResponse from "./api/response/StreamsListResponse";
import { useJsonAsyncStorage } from "./Utils";

interface ApiStorage {
    username?: string
    login?: LoginResponse
}

const detectCore = (hosting: HostingsResponse) => {
    for (const region in hosting.regions) {
        for (const zone in hosting.regions[region].zones) {
            for (const host in hosting.regions[region].zones[zone].hostings) {
                const picked = hosting.regions[region].zones[zone].hostings[host]
                if (picked.available) {
                    return picked
                }
            }
        }
    }
}

class API implements ApiInterface {

    apiUrl: string
    user?: string
    token?: string
    sInfo: ServiceInfo

    constructor(serviceInfo: ServiceInfo, user?: string, token?: string) {
        this.apiUrl = serviceInfo.api.replace('{username}', user || '')
        this.user = user
        this.token = token
        this.sInfo = serviceInfo
    }

    authHeaders = (headers?: { [key: string]: string }) => {
        if (this.token && !headers?.Authorization) {
            if (!headers) headers = {}
            headers.Authorization = this.token
        }
        return headers
    }

    get: <T> (path: string, params?: any, headers?: { [key: string]: string }) => Promise<T> =
        (path, params, headers) => apiGet(this.apiUrl + path, params, this.authHeaders(headers))

    post: <T> (path: string, params?: any, headers?: { [key: string]: string }) => Promise<T> =
        (path, params, headers) => apiPost(this.apiUrl + path, params, this.authHeaders(headers))

    hostings = async () => this.get<HostingsResponse>('hostings')

    serviceInfo = () => this.get<ServiceInfo>('service/info')

    async login(login: LoginRequest) {
        const result = await this.post<LoginResponse>('auth/login', login)
        this.user = login.username
        this.token = result.token
        this.apiUrl = this.sInfo.api.replace('{username}', login.username || '')
        return result
    }

    async logout() {
        const result = await this.post<{}>('auth/logout')
        this.token = undefined
        return result
    }

    register = async (user: NewUserRequest) => {
        const hosting = detectCore(await this.hostings())
        if (hosting) {
            return apiPost<NewUserResponse>(hosting.availableCore + '/users', user)
        } else {
            throw Error('No Cores available!')
        }
    }

    accessRequest = async (auth: AuthRequest) => {
        return this.sInfo?.access
            ? apiPost<AuthResponse>(this.sInfo.access, auth)
            : this.post<AuthResponse>('access', auth)

    }

    accessList = () => this.get<AccessListResponse>('accesses')
    accessCreate = (access: AccessCreateRequest) => this.post<AccessCreateResponse>('accesses', access)
    accessCheckApp = (access: AccessCheckRequest) => this.post<AccessCheckResponse>('accesses/check-app', access)
    requestAccess = async (access: Array<{ name: string, id: string, level: "read" | "contribute" | "manage" | 'create-only' }>): Promise<AccessInfo[]> => {
        const accesses = (await this.accessList()).accesses
        const accessList = accesses.map(x => x.name)

        const accessBatch = access.filter(x => !accessList.includes(x.name))
        .map(x => this.accessCreate({
            name: x.name,
            permissions: [{
                streamId: x.id,
                level: x.level
            }]
        }))
        .map(async x => await x)
        .map(async x => (await x).access)
        
        const accessNew = await Promise.all(accessBatch)

        return [...accessNew, ...accesses].filter(x => access.find(y => y.name == x.name))
    }

    accessInfo = () => this.get<AccessInfo>('access-info')
    streamsList = (req?: StreamsListRequest) => this.get<StreamsListResponse>('streams', req)
    eventsList = (req?: EventsListRequest) => this.get<EventsListResponse>('events', req)
    batchCall = (req: any) => this.post<any>('', req)
}

class SessionAPI extends API {

    storage: ReturnType<typeof useJsonAsyncStorage>

    constructor(storage: string, serviceInfo: ServiceInfo, user?: string, token?: string) {
        super(serviceInfo, user, token);
        this.storage = useJsonAsyncStorage(storage)
    }

    async login(login: LoginRequest) {
        const result = await super.login(login)
        await this.storage.setItem({
            login: result,
            username: login.username
        } as ApiStorage)

        return result
    }

    async logout() {
        const result = await super.logout()
        await this.storage.removeItem()
        return result
    }
}

const initApi = async (pryvServiceInfoUrl: string) => {
    const serviceInfo: ServiceInfo = await apiGet(pryvServiceInfoUrl)
    return (user?: string, token?: string) => new API(serviceInfo, user, token)
}

const initFromEndpoint = async (pryvServiceInfoUrl: string) => {
    const serviceInfo: ServiceInfo = await apiGet(pryvServiceInfoUrl)
    return (endpoint: string) => {
        const url = new URL(endpoint)
        return new API(serviceInfo, url.pathname.replaceAll('/', ''), url.username)
    }
}

const SessionApi = async (pryvServiceInfoUrl: string, username?: string) => {
    const serviceInfo: ServiceInfo = await apiGet(pryvServiceInfoUrl)
    const { getItem } = useJsonAsyncStorage('@api')
    const user: ApiStorage = (await getItem())

    return new SessionAPI('@api', serviceInfo, username || user?.username, user?.login?.token)
}

const PRYV_HOST = 'https://api.med.senopi.com/reg/service/info'
export default async (username?: string) => SessionApi(PRYV_HOST, username)

const UserAPI = async (username?: string, token?: string) => await initApi(PRYV_HOST).then(x => x(username, token));
const EndpointAPI = async (endpoint: string) => await initFromEndpoint(PRYV_HOST).then(x => x(endpoint));
export { UserAPI, EndpointAPI };


//TODO extract Clocks API to a separate module
const CLOCKS_HOST = 'https://api.med.senopi.com/clocker/'
// const CLOCKS_HOST = 'http://localhost:8080/'

interface Reminder {
    enabled: boolean
    hour?: number
    minutes?: number
    timezone?: string
}

const ClocksAPI = () => {

    return {
        addSchedule: async (data: {deviceToken: string, schedule: {[key: string]: Reminder}}) => apiCall("POST", CLOCKS_HOST + "add-schedule", data),
        getData: (apiEndpoint: string) => {
            const url = new URL(CLOCKS_HOST + "reportCsv")
            url.search = new URLSearchParams({apiEndpoint: apiEndpoint}).toString()
            return url.toString()
        }
    }
}

export {Reminder, ClocksAPI}