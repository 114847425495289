import { useState } from "react";
import { Image, StyleProp, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from "react-native";

interface Props {
    items: Map<string, string>,
    selected: string,
    onSelected: (key: string) => void,
    isActive: boolean,
    style?: StyleProp<ViewStyle> | undefined;
}

export default ({ style, items: values, selected, isActive, onSelected, ...rest }: Props) => {

    const [isOpen, setOpen] = useState(false)
    const setValue = (x: string) => {
        onSelected(x)
        setOpen(false)
    }

    const valuesShow = [...values].filter(x => x[0] != selected)
    const arrowIcon = isActive ? require('../../assets/icon_triangle_active.png') : require('../../assets/icon_triangle_inactive.png')

    const tabStyle = [styles.value_label, styles.tab_item]
    if (isActive) tabStyle.push(styles.tab_item_active)

    const currentStyle = [styles.current, styles.current_block]
    if (isActive) currentStyle.push(styles.current_active)

    return (
        <View style={[styles.container, style]} {...rest}>
            <TouchableOpacity onPress={() => { if(valuesShow.length > 0) {setOpen(!isOpen)}}}  >
                <View style={currentStyle}>
                    <Text style={tabStyle} >{values.get(selected)}</Text>
                    <Image source={(arrowIcon)} style={styles.icon} />
                </View>
            </TouchableOpacity>
            {isOpen && <View style={[styles.drop]}>
                {valuesShow.map(x => <TouchableOpacity style={{ width: "100%" }} key={x[0]} onPress={() => setValue(x[0])}>
                    <Text numberOfLines={1} style={[styles.value_list_item, styles.value_label]} >{x[1]}</Text>
                </TouchableOpacity>)}
            </View>}
        </View>
    )
}


const styles = StyleSheet.create({

    container: {
        flexDirection: "column",
        justifyContent: "flex-start",
        marginLeft: 14,
        marginRight: 14,
        zIndex: 1000,
    },

    drop: {
        flexDirection: "column",
        justifyContent: "flex-start",
        position: "absolute",
        width: "100%",
        top: 34,
        zIndex: 1000,
        borderColor: "#FFFFFFB2",
        borderWidth: 1,
        borderRadius: 18,
        paddingTop: 3,
        paddingBottom: 3,
        paddingStart: 14,
        paddingEnd: 14,
        backgroundColor: "#48A9C5",
    },

    tab_item: {
        color: "#FFFFFF80",
        fontFamily: "SegoeRegular",
        fontSize: 15,
        textAlign: "center"
    },

    tab_item_active: {
        color: "#FFFFFF",
    },

    icon: {
        width: 14,
        height: 12,
        marginStart: 5
    },

    current: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },

    current_block: {
        paddingStart: 14,
        paddingEnd: 14,
        paddingTop: 5,
        paddingBottom: 6,
        borderRadius: 18,
        borderColor: "#FFFFFFB2",
        borderWidth: 1,
    },

    current_active: {
        backgroundColor: "#48A9C5",
        borderColor: "#48A9C5",
    },


    value_label: {
        color: "#FFFFFF",
        fontFamily: "SegoeRegular",
        fontSize: 15
    },

    value_list_item: {
        paddingTop: 3,
        paddingBottom: 3,
    },
})
