import { FC } from "react"
import { StyleProp, StyleSheet, Text, TouchableOpacityProps, View, ViewStyle } from "react-native"
import { units } from "../model/StatsModel"
import StatsView from "./StatsView"

interface ProfileStatsAverageProps extends TouchableOpacityProps {
    title: string,
    stats: Array<{value: number | undefined, unit: units, label: string}>,
    style?: StyleProp<ViewStyle>
}

const ProfileStatsAverage: FC<ProfileStatsAverageProps> = ({title, stats, style, ...rest }) => {

    return (
        <View style={[styles.container, style]} {...rest}>
            <Text style={styles.title}>{title}</Text>
            <View style={[styles.stats, style]}>
                {stats.map(x => <StatsView key={x.label} style={{flex: 1}} label={x.label} value={x.value} unit={x.unit} />)}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({

    container: {
        flexDirection: "column",
        backgroundColor: "#0A100FB2",
        borderRadius: 15,
        paddingStart: 5,
        paddingTop: 8,
        paddingEnd: 5,
        paddingBottom: 15,
        alignItems: "stretch",
        marginBottom: 12
    },

    title: {
        color: "#48A9C5",
        fontFamily: "SegoeRegular",
        fontSize: 17,
        textAlign: "center",
        marginBottom: 10
    },

    stats: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
    },

})

export default ProfileStatsAverage