import { StyleSheet, TextStyle, ViewStyle } from 'react-native';

interface ResetPasswordScreenStyles {
  container: ViewStyle;
  button: ViewStyle;
  subtitle: TextStyle;
  form: ViewStyle;
  dialogText: TextStyle;
}

export const styles = StyleSheet.create<ResetPasswordScreenStyles>({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  button: {
    marginTop: 86,
    marginBottom: 11,
  },

  subtitle: {
    color: '#FFFFFF',
    fontSize: 20,
    marginTop: 20,
  },

  form: {
    width: 260,
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 84,
    marginBottom: 69,
  },

  dialogText: {
    color: '#FFFFFF',
    fontSize: 18,
    margin: 20,
  },
});
