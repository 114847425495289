import { FC } from "react"
import { Image, ImageSourcePropType, StyleProp, StyleSheet, Text, TextStyle, TouchableOpacity, TouchableOpacityProps, ViewStyle } from "react-native"
import IconText from "./IconText"

interface IconButtonProps extends TouchableOpacityProps {
    label: string,
    icon?: ImageSourcePropType,
    iconWidth?: number,
    iconHeight?: number,
    style?: StyleProp<ViewStyle>,
    labelStyle?: StyleProp<TextStyle>,
}

const IconButton: FC<IconButtonProps> = ({ label, style, labelStyle, icon, iconWidth, iconHeight, ...rest }) => {

    return (
        <TouchableOpacity {...rest}>
            <IconText style={style} label={label} icon={icon} iconWidth={iconWidth} iconHeight={iconHeight} labelStyle={[styles.label, labelStyle]} />
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({

    label: {
        textTransform: "uppercase",
        fontSize: 18,
    }

})

export default IconButton