import { StyleProp, StyleSheet, Text, View, ViewProps, ViewStyle } from "react-native";

interface Props extends ViewProps {
    maxValue: number,
    value: number,
}

export default ({ style, maxValue, value, ...rest }: Props) => {

    const completedPercent = Math.round(value / maxValue * 100)

    const progressStyle: Array<StyleProp<ViewStyle>> = [styles.progress_value]
    progressStyle.push(completedPercent ? { width: completedPercent + "%", minWidth: 50 } : styles.progress_empty)
    
    return <View style={[styles.progress_container, style]}>
        <Text style={progressStyle} numberOfLines={1}>{completedPercent} %</Text>
    </View>

}

const styles = StyleSheet.create({

    progress_container: {
        padding: 3,
        width: "100%",
        backgroundColor: "#0A100FB3",
        borderRadius: 20
    },

    progress_value: {
        width: "50%",
        color: "#FFFFFF",
        fontSize: 18,
        lineHeight: 20,
        padding: 3,
        fontFamily: "SegoeSemibold",
        backgroundColor: "#48A9C5",
        borderRadius: 20,
        alignContent: "center",
        textAlign: "center"
    },

    progress_empty: {
        width: "100%",
        backgroundColor: "#0A100FB3",
    }

})
