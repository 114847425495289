
type UserProfileType = 'user' | 'placebo' | 'supervisor' | 'subadmin' | 'admin' | 'headset' | 'client' | undefined
export default class UserProfile {

    username?: string = undefined;

    token?: string = undefined;

    firstName?: string = undefined;

    lastName?: string = undefined;

    language?: string = undefined;

    type?: UserProfileType = undefined;

    parent?: UserProfile = undefined;

    isPaused?: boolean = undefined;

    commonData: CommonData = {};
    
    privateData: PrivateData = {};

    constructor(type: UserProfileType = undefined, username: string | undefined = undefined) {
        this.username = username
        this.type = type
    }

    
}
