import { FC } from "react"
import { StyleProp, StyleSheet, Text, TouchableOpacityProps, View, ViewStyle } from "react-native"
import { units } from "../model/StatsModel"
interface StatsViewProps extends TouchableOpacityProps {
    label: string
    value: number | undefined
    isUp?: boolean
    unit?: units
    style?: StyleProp<ViewStyle>
}

const formatUnit = (value: number, unit?: units): string => {
    switch (unit) {
        case "time_minutes":
            return value + " min"

        case "time_seconds":
            return value + " s"

        case "time_duration":
            const h = Math.round(value / 3600)
            const m = Math.round((value % 3600) / 60)
            const pad = h && m ? 2 : 0
            const H = h ? h.toString().padStart(pad, '0') + ' hr' : ''
            const M = m ? m.toString().padStart(pad, '0') + ' min' : '0 min'
            return [H, M].join(' ')

        case "distance_m":
            const round = Math.floor(value) != value ? 2 : 0
            return value > 1000 ? (value / 1000).toFixed(round) + " km" : Math.floor(value) + " m"

        case "count":
        default:
            const round2 = Math.floor(value) != value ? 2 : 0
            return value.toFixed(round2)
    }
}

const StatsView: FC<StatsViewProps> = ({ label, value, unit, isUp, style, ...rest }) => {

    return (
        <View style={[styles.container, style]} {...rest}>
            <Text style={styles.value} >{value && formatUnit(value, unit)}</Text>
            <Text style={styles.label}>{label}</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: "column",
        alignItems: "center",
    },

    value: {
        fontFamily: "SegoeRegular",
        fontSize: 20,
        textAlign: "center",
        color: "#FFFFFF",
        marginBottom: 5
    },

    label: {
        color: "#FFFFFF",
        opacity: 0.7,
        fontFamily: "SegoeLight",
        textAlign: "center",
        fontSize: 14,
        // maxWidth: 60,
        height: 37,
        textAlignVertical: "center"
    },


})

export default StatsView