import ApiInterface from "../api/ApiInterface"
import { createStream } from "../PryvUtils"


export default async (api: ApiInterface): Promise<any> => {

    const result = await api.streamsList()

    const activityStreams = [{ id: 'physical', name: 'Physical' }, { id: 'focus', name: 'Focus' }, { id: 'memory', name: 'Memory' }]
    const activityMetricsStreams = [
        { id: 'SessionTime', name: 'Session Time' },
        { id: 'CorrectlyCaught', name: 'Correctly Caught' },
        { id: 'CorrectlyNotCaught', name: 'Correctly Not Caught' },
        { id: 'IncorrectlyCaught', name: 'Incorrectly Caught' },
        { id: 'IncorrectlyNotCaught', name: 'Incorrectly Not Caught' },
        { id: 'HandFlex', name: 'Hand Flex' }
    ]

    const activityStreams2 = [ { id: 'switch', name: 'Switch' }]
    const activityMetricsStreams2 = [
        { id: 'SessionTime', name: 'Session Time' },
        { id: 'Correct', name: 'Correct' },
        { id: 'Incorrect', name: 'Incorrect' },
    ]

    const profileStreams = [
        { id: 'user_profile', name: 'Profile' },
        { id: 'user_profile_first_name', name: 'First name', parentId: 'user_profile' },
        { id: 'user_profile_last_name', name: 'Last name', parentId: 'user_profile' },
        { id: 'user_profile_reminders_schedule', name: 'Reminders Schedule', parentId: 'user_profile' },
        { id: 'user_profile_tokens', name: 'Access tokens', parentId: 'user_profile' },
        { id: 'user_profile_is_placebo', name: 'Is Placebo User', parentId: 'user_profile' },
        { id: 'user_profile_is_admin', name: 'Is Admin User', parentId: 'user_profile' },
    ]

    const existingStreams = result.streams?.map(x => x.id)
    const createActivityStreams = activityStreams.filter(x => !existingStreams?.includes(x.id)).map(x => createStream(x.id, x.name))
    const createActivityMetricsStreams = activityStreams
        .map(x => x.id)
        .flatMap(x => activityMetricsStreams.filter(y => !existingStreams?.includes(x + '_' + y.id))
            .map(y => createStream(x + '_' + y.id, y.name, x)))

    const createActivityStreams2 = activityStreams2.filter(x => !existingStreams?.includes(x.id)).map(x => createStream(x.id, x.name))
    const createActivityMetricsStreams2 = activityStreams2
        .map(x => x.id)
        .flatMap(x => activityMetricsStreams2.filter(y => !existingStreams?.includes(x + '_' + y.id))
            .map(y => createStream(x + '_' + y.id, y.name, x)))

    const createProfileStreams = profileStreams.filter(x => !existingStreams?.includes(x.id)).map(x => createStream(x.id, x.name))
    
    const createStreams = [...createActivityStreams, ...createActivityMetricsStreams, ...createProfileStreams, ...createActivityStreams2, ...createActivityMetricsStreams2]
    console.log(createStreams)
    if (createStreams.length > 0) {
        const batchResult = await api.batchCall(createStreams)
        console.log(batchResult)
        return batchResult
    }

    return []
}