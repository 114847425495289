import { FC } from "react"
import { Image, ImageSourcePropType, StyleProp, StyleSheet, Text, View, ViewProps, ViewStyle, TextStyle } from "react-native"

interface IconTextProps extends ViewProps {
    label: string,
    icon?: ImageSourcePropType,
    iconWidth?: number,
    iconHeight?: number,
    style?: StyleProp<ViewStyle>,
    labelStyle?: StyleProp<TextStyle>,
}

const IconText: FC<IconTextProps> = ({ label, style, labelStyle,  icon, iconWidth, iconHeight, ...rest }) => {

    return (
        <View style={[styles.button, style]} {...rest}>
            {icon && <Image source={icon} style={{ width: iconWidth, height: iconHeight, marginEnd: 8}} />}
            <Text style={[styles.label, labelStyle]}>{label}</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    button: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },

    label: {
        fontFamily: "SegoeRegular",
        fontSize: 20,
        textAlign: "center",
        color: "#FFFFFF",
    }
})

export default IconText