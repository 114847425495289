interface ResponseError {
    message: string
}

const toJson: <T> (response: Response) => Promise<T> = (response: Response) => response.headers.get('content-length') ? response.json() : {};

const apiCall: <T> (method: 'POST' | 'GET', url: string, params?: any, headers?: { [key: string]: string }) => Promise<T> = async (method, url, params?, headers?) => {
    if (method == 'GET' && params) {
        const urlWithParams = new URL(url)
        urlWithParams.search = new URLSearchParams(params).toString()
        url = urlWithParams.toString()
    }
    // console.log(JSON.stringify(params));
    const response = await fetch(url, {
        method: method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Origin': 'https://localhost',
            ...headers
        },
        body: params && !['GET', 'HEAD'].includes(method) ? JSON.stringify(params) : undefined,
    });

    if (response.status >= 200 && response.status < 300) {
        return toJson(response);
    } else {
        let error = {message: response.statusText} as ResponseError;
        console.log(JSON.stringify(await toJson(response)));
        throw error
    }
}

const apiGet: <T> (url: string, params?: any, headers?: { [key: string]: string }) => Promise<T> = async (url, params, headers) => apiCall('GET', url, params, headers)
const apiPost: <T> (url: string, params?: any, headers?: { [key: string]: string }) => Promise<T> = async (url, params, headers) => apiCall('POST', url, params, headers)

export { toJson, apiCall, apiGet, apiPost, ResponseError }