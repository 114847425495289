import { StyleSheet, TouchableOpacityProps, View } from "react-native"


export default ({ style, children, ...rest }: TouchableOpacityProps) => {

    return (
        <View style={[styles.container, style]} {...rest}>
            {children}
        </View>
    )
}

const styles = StyleSheet.create({

    container: {
        paddingTop: 15,
        paddingBottom: 15, 
        borderTopColor: "#FFFFFF99",
        borderTopWidth: 1
    },
})
