import { FC } from "react"
import { StyleProp, StyleSheet, Text, TextInput, TextInputProps, TextStyle, View, ViewStyle } from "react-native"

interface TitledInputProps extends TextInputProps {
    label: string
    subLabel?: JSX.Element
    style?: StyleProp<ViewStyle>
    labelStyle?: StyleProp<ViewStyle>
    inputStyle?: StyleProp<TextStyle>
}


const TitledInput: FC<TitledInputProps> = ({ label, subLabel, style, labelStyle, inputStyle, ...rest }) => {

    return (
        <View style={[styles.container, style]} >
            <View style={styles.labelContainer}>
                <Text style={[styles.label, labelStyle]}>{label}</Text>
                {subLabel}
            </View>
            <TextInput
                style={[styles.input, inputStyle]}
                {...rest}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "100%"
    },

    labelContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 10
    },

    label: {
        fontFamily: "SegoeRegular",
        fontSize: 18,
        lineHeight: 22,
        color: "#FFFFFF",
    },

    input: {
        alignItems: 'center',
        paddingHorizontal: 10,
        fontFamily: "SegoeRegular",
        fontSize: 18,
        lineHeight: 22,
        color: "#FFFFFF",
        borderWidth: 1,
        borderRadius: 9,
        minHeight: 51,
        borderColor: "#FFFFFFB3"
    }
});

export default TitledInput