import { Image, StyleProp, TouchableOpacity, View, ViewProps, ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

interface Props extends ViewProps {
    onBackPressed?: () => void
    rightComponent?: JSX.Element
    style?: StyleProp<ViewStyle>
}


export default ({ onBackPressed, rightComponent, style, ...rest }: Props) => {

    const intests = useSafeAreaInsets()

    return (
        <View style={[{ paddingTop: intests.top, backgroundColor: '#0A100FAE', borderBottomStartRadius: 15, borderBottomEndRadius: 15, flexDirection: 'row', justifyContent: 'space-between' }, style]} {...rest}>
            <TouchableOpacity style={{ padding: 14 }} onPress={onBackPressed} >
                <Image source={require('../../assets/icon_back.png')} style={{ width: 16, height: 16 }} />
            </TouchableOpacity>
            <Image source={require('../../assets/logo_secondary.png')} style={{ width: 131, height: 33, alignSelf: 'flex-end', marginBottom: 5 }} />
            { rightComponent ? rightComponent
            : <View style={{ width: 44 }} /> }
        </View>)
}
