import { FC } from 'react';
import Calendar from 'react-calendar';
import { Dimensions, Platform, StyleSheet, TouchableWithoutFeedback, View, ViewProps } from 'react-native';

if(Platform.OS == 'web') {
    require('react-calendar/dist/Calendar.css')
}

interface WebDatePickerProps extends ViewProps {
    value?: Date
    onDateChange?: (date: Date) => void
    onOnCloseRequest?: () => void
}

const WebDatePicker: FC<WebDatePickerProps> = ({value, onDateChange, onOnCloseRequest, ...rest}) => {

    return <View  style={{flexDirection: "column", alignItems:"center"}}>
        {/* <TouchableWithoutFeedback onPress={onOnCloseRequest} ><View style={styles.overlay} /></TouchableWithoutFeedback> */}
        <View style={styles.container}><Calendar onChange={onDateChange} defaultValue={value} /></View>
    </View>
}

const styles = StyleSheet.create({
    container: {
        // padding: 4,
        backgroundColor: "#FFFFFF",
        position: 'absolute',
        top: -300,
        left: -55,
        width: 300,
        // height: 300,
    },

    overlay: {
        backgroundColor: "#FFFFFF44",
        position: 'absolute',
        width: Dimensions.get('window').width,
        height: 100
        // left: 0,
        // top: 0,
        // right: 0,
        // bottom: 0,
        // left: - Dimensions.get('window').width,
        // top: - Dimensions.get('window').height,
        // width: Dimensions.get('screen').width,
        // height: Dimensions.get('screen').height,
    }
})

export default WebDatePicker