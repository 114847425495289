import { FC } from "react"
import { StyleProp, StyleSheet, Text, TouchableOpacity, TouchableOpacityProps, ViewStyle } from "react-native"

interface CheckedButtonProps extends TouchableOpacityProps {
    isChecked?: boolean
    label: string
    style?: StyleProp<ViewStyle>
    onCheckedChange?: (checked: boolean) => void
}

const CheckedButton: FC<CheckedButtonProps> = ({ isChecked, label, style, onCheckedChange, ...rest }) => {

    const checked = isChecked || false

    const onPress = () => {
        if (onCheckedChange) {
            onCheckedChange(!checked)
        }
    }

    const buttonStyle = [styles.button, style]
    const labelStyle = [styles.label]
    if(checked) {
        buttonStyle.push(styles.buttonChecked)
        // labelStyle.push(styles.labelChecked)
    }

    return (
        <TouchableOpacity style={buttonStyle} {...rest} onPress={onPress}>
            <Text style={labelStyle}>{label}</Text>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    button: {
        backgroundColor: "#0A100F",
        opacity: 0.7,
        borderRadius: 5,
        justifyContent: "center",
        paddingTop: 6,
        paddingBottom: 6
    },

    buttonChecked: {
        backgroundColor: "#48A9C5",
        opacity: 1,
    },

    label: {
        fontFamily: "SegoeRegular",
        fontSize: 18,
        textAlign: "center",
        color: "#FFFFFF",
    },

    labelChecked: {
        fontFamily: "SegoeRegular",
    }
})

export default CheckedButton